article a{

    text-decoration: none;
    color: black;
}

article a:hover{

    color:blue;
}

article a:active { font-weight: bold;}
