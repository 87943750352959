body, html{

    margin: 0px;
    padding: 0px;
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
}

.nav_link{

    text-decoration: none;
    color: black;
}

#nav_bar{

    align-items: center;
}

.current{
    border-bottom: 2px solid black;
}

#header{

    /* background-image: url("../assets/portland_skyline.jpg");
    background-size: cover;
    background-repeat: no-repeat; */
    background-color: black;
    height: 35vh;
    display: flex;
    align-items: center;
    padding: 0;
}

#headshot{
    border: solid 3px white;
    border-radius: 5px;
    width: calc(100px + 10%);

}

.icon{
    float: right;
    padding: 15px;
}

.icon_div{

    float:right;
    display:block;
    width: 100%;
}

.column1 {
    width: calc(50% - 9vw);

}

.column2{
    width: calc(50% + 8vw);

}

@media screen and (max-width: 480px) { 
    /* Force icon container width to 30% to account for image shrinkage */
    .column1 {
      width: 35%;
    }
}

footer{

    background-color: black;
    position: absolute; 
    bottom: 0; 
    width: 100%
}


.footer_icon{

    display: inline-block;
    padding: 25px;
    
}